//
// Copyright 2021 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import FilterInputField from "src/components/taps/mobile/filter/FilterInputField";
import SortInputField from "src/components/taps/mobile/sort/SortInputField";

export default function TapsActivityFilterDialog() {
  return (
    <>
      <FilterInputField path={"taps:activityFilters"}/>
      <SortInputField path={"taps:activitySorts"}/>
    </>
  );
}
