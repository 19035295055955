import {
  createComboBoxEditorColumn,
  createDataIndexEditorColumn,
  createDateEditorColumn,
  createTextEditorColumn,
} from "eai-configurator-ui/components/configuration/table/ConfigurationTableEditor";
import * as Config from "eai-configurator-ui/components/configuration/utils/ConfigurationRegistry";

// maps field names to markers
export const markerByField = {
  // activity
  durationPercentComplete: "P",
  physicalPercentComplete: "H",
  unitsPercentComplete: "U",
  remainingDuration: "R",

  // resource assignment
  actualUnits: "C",
  remainingUnits: "R",
  resourceUnitsPercentComplete: "U",

  // step
  percentComplete: "P",
};

export function createActivityColumns(searchVisible) {
  const path = "taps:activities";
  const activityPath = `${path}:activity`;

  const hiddenColumns = Config.fromPath(`taps:hiddenColumns`).data.value;
  const createTextColumn = (key, searchVisible, props) => {
    return createTextEditorColumn(`${activityPath}:${key}`, searchVisible, {
      editable: false,
      ...props,
    });
  };
  const createEditableTextColumn = (key, props) => {
    return createTextEditorColumn(`${activityPath}:${key}`, null, {
      hidden: hiddenColumns.includes(key),
      editable: true,
      ...props,
    });
  };

  // Parameter 'searchVisible' is provided for columns which support filtering
  const columns = [
    createTextColumn("seq"),
    createTextColumn("projId", searchVisible),
    createTextColumn("actId", searchVisible),
    createTextColumn("actName", searchVisible, {
      headerStyle: { width: "25%" },
    }),
    createComboBoxEditorColumn(`${activityPath}:actPercentCompleteType`, null, {
      headerStyle: { width: "120px" },
    }),
    createEditableTextColumn("durationPercentComplete", {
      style: durationPercentCompleteStyle,
      align: "right",
      headerStyle: { width: "120px" },
    }),
    createEditableTextColumn("physicalPercentComplete", {
      style: physicalPercentCompleteStyle,
      align: "right",
      headerStyle: { width: "120px" },
    }),
    createEditableTextColumn("unitsPercentComplete", {
      style: unitsPercentCompleteStyle,
      align: "right",
      headerStyle: { width: "100px" },
    }),
    createEditableTextColumn("remainingDuration", {
      style: remainingDurationStyle,
      align: "right",
      headerStyle: { width: "100px" },
    }),
    createTextColumn("startDate"),
    createTextColumn("endDate"),
    createComboBoxEditorColumn(activityPath + ":status", null, {
      style: statusStyle,
      editable: false,
    }),
    createDataIndexEditorColumn(),
    {
      dataField: "objectId",
      hidden: true,
    },
    {
      dataField: "modifiedFields",
      hidden: true,
    },
    {
      dataField: "mobilePercentCompleteType",
      hidden: true,
    },
    {
      dataField: "active",
      hidden: true,
    },
  ];
  return columns;
}

export function durationPercentCompleteStyle(value, row, index) {
  if (hasValue(row.modifiedFields, "DURATION_PERCENT_COMPLETE")) {
    return {
      align: "right",
      "background-color": "lime",
      color: "black",
    };
  } else if (
    !hasValue(row.modifiedFields, "REMAINING_DURATION") &&
    (hasValue(row.modifiedFields, "UNITS_PERCENT_COMPLETE") ||
      hasValue(row.modifiedFields, "PHYSICAL_PERCENT_COMPLETE"))
  ) {
    return {
      align: "right",
      "background-color": "pink",
      color: "black",
    };
  } else {
    return {
      align: "right",
      "background-color": "#EEE",
      color: "black",
    };
  }
}

export function physicalPercentCompleteStyle(value, row, index) {
  return getSimpleTapsStyle(value, row, index, "PHYSICAL_PERCENT_COMPLETE");
}

export function unitsPercentCompleteStyle(value, row, index) {
  return getSimpleTapsStyle(value, row, index, "UNITS_PERCENT_COMPLETE");
}

export function remainingUnitsStyle(value, row, index) {
  return getSimpleTapsStyle(value, row, index, "REMAINING_UNITS");
}

export function actualUnitsStyle(value, row, index) {
  return getSimpleTapsStyle(value, row, index, "ACTUAL_UNITS");
}

export function remainingDurationStyle(value, row, index) {
  return getSimpleTapsStyle(value, row, index, "REMAINING_DURATION");
}

export function statusStyle(value, row, index) {
  return getSimpleTapsStyle(value, row, index, "STATUS");
}

export function completedStyle(value, row, index) {
  return getSimpleTapsStyle(value, row, index, "COMPLETED");
}

export function percentCompleteStyle(value, row, index) {
  return getSimpleTapsStyle(value, row, index, "PERCENT_COMPLETE");
}

export function cancelledStyle(value, row, index) {
  return getSimpleTapsStyle(value, row, index, "CANCELLED");
}

export function startDateStyle(value, row, index) {
  if (hasValue(row.modifiedFields, "START_DATE")) {
    return {
      "background-color": "lime",
      color: "black",
    };
  }
  return "";
}

export function activityRowStyle(row, index) {
  var i;
  if (row.active === true) {
    // do not mark the activity row as active if a resource assignment is active
    if (row.resourceAssignments) {
      for (i = 0; i < row.resourceAssignments.length; i++) {
        if (row.resourceAssignments[i].active) {
          return "";
        }
      }
      for (i = 0; i < row.steps.length; i++) {
        if (row.steps[i].active) {
          return "";
        }
      }
    }
    return {
      "background-color": "#fffef4",
    };
  }
  return {};
}

export function endDateStyle(value, row, index) {
  if (hasValue(row.modifiedFields, "END_DATE")) {
    return {
      "background-color": "lime",
      color: "black",
    };
  }
  return "";
}
export function barcodeCellStyle(value, row, index) {
  return {
    class: "barcode_container, barcode",
  };
}
function hasValue(array, value) {
  if (!array) {
    return false;
  }
  var i = null;
  for (i = 0; array.length > i; i += 1) {
    if (array[i] === value) {
      return true;
    }
  }

  return false;
}

function getSimpleTapsStyle(value, row, index, field) {
  if (hasValue(row.modifiedFields, field)) {
    return {
      "background-color": "lime",
      color: "black",
    };
  } else {
    return {
      "background-color": "#EEE",
      color: "black",
    };
  }
}
