//
// Copyright 2021 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import Box from "@material-ui/core/Box";
import ConfigurationCheckBox from "eai-configurator-ui/components/configuration/base/ConfigurationCheckBox";
import ConfigurationTimeZonedDatePicker from "eai-configurator-ui/components/configuration/base/ConfigurationTimeZonedDatePicker";
import Hidden from "@material-ui/core/Hidden";
import {useSaveConfiguration} from "src/components/taps/save/ConfigurationSaveUtils";
import ConfigurationComboBox from "eai-configurator-ui/components/configuration/base/ConfigurationComboBox";

export default function SettingsHeader({path, autoSaveOnEveryEdit, showMobileInterface}) {
  const [forceSave, setForceSave] = React.useState(0);
  const saveConfiguration = useSaveConfiguration();

  React.useEffect(() => {
    if (forceSave) {
      issueSaveRequest();
    }
  }, [forceSave]);

  const issueSaveRequest = () => {
    saveConfiguration();
  };

  const handleChanged = () => {
    if (autoSaveOnEveryEdit) {
      setForceSave(forceSave + 1);
    }
  };

  let options = [
    <Box style={{flex: 1}}>
      <ConfigurationTimeZonedDatePicker
        path={path + ":updateDate"}
        handleChanged={handleChanged}
      />
    </Box>,
    <Box flexShrink={1}>
      <ConfigurationCheckBox
        path={path + ":updateMilestones"}
        handleChanged={handleChanged}
      />
    </Box>,
    <Box flexShrink={1}>
      <ConfigurationCheckBox
        path={path + ":runOutOfSequenceReport"}
        handleChanged={handleChanged}
      />
    </Box>,
    <Box flexShrink={1}>
      <ConfigurationCheckBox
        path={path + ":dissolveCancelledActivities"}
        handleChanged={handleChanged}
      />
    </Box>];

  if (showMobileInterface) {
    options.push(
      <Box flexShrink={1}>
        <ConfigurationComboBox
          path={path + ":mobilePercentCompleteType"}
          handleValueChange={handleChanged}
        />
      </Box>);
  }

  return (
    <div>
      <Hidden mdUp>
        <Box>{options}</Box>
      </Hidden>
      <Hidden smDown>
        <Box display="flex" flexDirection="row">
          {options}
        </Box>
      </Hidden>
    </div>
  );
}
