//
// Copyright 2021 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import Application from "eai-configurator-ui/components/Application";
import ConfigurationDashboard from "eai-configurator-ui/components/configuration/ConfigurationDashboard";
import AccountButton from "eai-configurator-ui/components/header/buttons/AccountButton";
import HelpButton from "eai-configurator-ui/components/header/buttons/HelpButton";
import MessagesButton from "eai-configurator-ui/components/header/buttons/MessagesButton";
import {HashRouter as Router} from "react-router-dom";
import ActivityTablePage from "src/components/taps/pages/ActivityTablePage";
import OutOfSequenceReportPage from "src/components/taps/pages/OutOfSequenceReportPage";
import Login from "eai-configurator-ui/components//login/Login";
import NavigateToMainPageButton from "src/components/taps/buttons/NavigateToMainPageButton";
import ManageUsersButton from "eai-configurator-ui/components/header/buttons/ManageUsersButton";
import ManageUsersDialog from "eai-configurator-ui/components/configuration/dialog/ManageUsersDialog";
import {isMobile} from "react-device-detect";
import DateDialog from "src/components/taps/settings/DateDialog";
import TapsMobileActivityPage from "src/components/taps/mobile/TapsMobileActivityPage";
import TapsSettingsMobilePage from "src/components/taps/mobile/TapsSettingsMobilePage";
import SettingsIcon from "@material-ui/icons/Settings";
import LabelIcon from "@material-ui/icons/Label";
import * as Utils from "eai-configurator-ui/components/Utils";

function TapsApplication() {
  // When mobile app loads, show the date selection dialog
  const [dateDialogOpen, setDateDialogOpen] = React.useState(true);
  const [manageUsersDialogOpen, setManageUsersDialogOpen] = React.useState(
    false
  );
  const [actionButtons, setActionButtons] = React.useState([]);
  const [appSettings, setAppSettings] = React.useState({uiMode: "UNDEFINED"});
  const [showMobileInterface, setShowMobileInterface] = React.useState(false);
  const [loginCounter, setLoginCounter] = React.useState(0);
  let activityPage;
  let navigation;

  const retrieveSettings = () => {
    Utils.fetchData(
      "taps/retrieveSettings",
      {
        method: "GET"
      },
      false
    )
      .then((data) => {
        setAppSettings(data);
        console.table(data)
      })
      .catch((response) => {
        Utils.getErrorMessage(response, "Request failed.").then((message) => {
          console.log("Error retrieving settings: " + message)
        });
      })
  }

  const afterLoginCounter = () => {
    setLoginCounter((prevCounter) => {
      return prevCounter + 1;
    });
  }

  React.useEffect(() => {
    retrieveSettings();
  }, [])

  React.useEffect(() => {
    if (showMobileInterface) {
      Utils.fetchNonJsonDataUsingNonJson("taps/mobileApplicationMode", []);
    } else {
      Utils.fetchNonJsonDataUsingNonJson("taps/desktopApplicationMode", []);
    }
  }, [showMobileInterface, loginCounter]);

  React.useEffect(() => {
    if (appSettings.uiMode === "AUTOMATIC") {
      setShowMobileInterface(isMobile);
    } else if (appSettings.uiMode === "DESKTOP") {
      setShowMobileInterface(false);
    } else if (appSettings.uiMode === "MOBILE") {
      setShowMobileInterface(true);
    } else {
      console.log("Unknown UI mode, using automatic mode: " + appSettings.uiMode)
      setShowMobileInterface(isMobile);
    }
  }, [appSettings, isMobile]);

  if (showMobileInterface) {
    activityPage = (
      <div>
        <DateDialog
          path={"taps"}
          open={dateDialogOpen}
          handleClose={() => setDateDialogOpen(false)}
        />
        <TapsMobileActivityPage setActionButtons={setActionButtons}/>
      </div>
    );
    navigation = [
      {
        section: "Navigation",
        label: "Settings",
        icon: <SettingsIcon/>,
        component: (
          <TapsSettingsMobilePage setActionButtons={setActionButtons}/>
        ),
      },
      {
        section: "Navigation",
        label: "Activities",
        icon: <LabelIcon/>,
        component: activityPage,
      },
    ];
  } else {
    activityPage = <ActivityTablePage setActionButtons={setActionButtons}/>;
  }
  return (
    <Router>
      <Application
        login={<Login useDatabase appName={"TAPS"} afterLogin={afterLoginCounter}/>}
        application={{
          routes: [
            {
              path: "/taps",
              component: (
                <ConfigurationDashboard
                  navigationPanelWidth={160}
                  navigation={navigation}
                  welcomeContent={{
                    label: "TAPS",
                    component: activityPage,
                  }}
                  headerButtons={[
                    <MessagesButton/>,
                    <HelpButton/>,
                    <AccountButton
                      buttons={[
                        <ManageUsersButton
                          handleClick={() => setManageUsersDialogOpen(true)}
                        />,
                      ]}
                    />,
                  ]}
                  actionButtons={actionButtons}
                />
              ),
            },

            {
              path: "/out-of-sequence",
              component: (
                <ConfigurationDashboard
                  welcomeContent={{
                    label: "Out Of Sequence Report",
                    component: <OutOfSequenceReportPage/>,
                    configuration: "taps",
                  }}
                  headerButtons={[
                    <MessagesButton/>,
                    <HelpButton/>,
                    <AccountButton
                      buttons={[
                        <ManageUsersButton
                          handleClick={() => setManageUsersDialogOpen(true)}
                        />,
                      ]}
                    />,
                  ]}
                  actionButtons={[<NavigateToMainPageButton/>]}
                />
              ),
            },
          ],
          defaultRoute: "taps",
        }}
      >
        <ManageUsersDialog
          open={manageUsersDialogOpen}
          handleClose={() => setManageUsersDialogOpen(false)}
        />
      </Application>
    </Router>
  );
}

export default TapsApplication;
