//
// Copyright 2021 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FilterByProjectDialog from "src/components/taps/mobile/filter/dialog/FilterByProjectDialog";
import FilterByDateDialog from "src/components/taps/mobile/filter/dialog/FilterByDateDialog";
import FilterByCodeDialog from "src/components/taps/mobile/filter/dialog/FilterByCodeDialog";
import * as Config from "eai-configurator-ui/components/configuration/utils/ConfigurationRegistry";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
    whiteSpace: "nowrap",
  },
  inputCenter: {
    textAlign: "center",
    color: "red",
  },
}));

const getFilterConditionSummary = (config) => {
  if (config.key === "filterByProject") {
    const projectIdConfig = config.children.find(
      (grandchild) => grandchild.key === "projectIds"
    );
    if (projectIdConfig && projectIdConfig.data.value) {
      return "Project = " + projectIdConfig.data.value;
    }
  } else if (config.key === "filterByDate") {
    const fieldConfig = config.children.find(
      (grandchild) => grandchild.key === "field"
    );
    const dateConfig = config.children.find(
      (grandchild) => grandchild.key === "daysInFuture"
    );
    if (
      fieldConfig &&
      fieldConfig.data.value &&
      dateConfig &&
      dateConfig.data.value
    ) {
      return (
        fieldConfig.data.valueMap[fieldConfig.data.value] +
        " < " +
        dateConfig.data.valueMap[dateConfig.data.value]
      );
    }
  } else if (
    config.key === "filterByProjCode" ||
    config.key === "filterByActCode"
  ) {
    const codeNameConfig = config.children.find(
      (grandchild) => grandchild.key === "name"
    );
    const codeValueConfig = config.children.find(
      (grandchild) => grandchild.key === "values"
    );
    if (
      codeNameConfig &&
      codeNameConfig.data.value &&
      codeValueConfig &&
      codeValueConfig.data.value
    ) {
      return (
        codeNameConfig.data.value +
        " = " +
        codeValueConfig.data.value.join(", ")
      );
    }
  }
  return null;
};
const getDefinedOptions = (path) => {
  const config = Config.getDefault(path + ":filter");
  return config.children.map((child) => {
    const option = {
      id: child.key,
      key: child.key,
      label: child.data.label,
    };
    return option;
  });
};
const getConfiguredOptions = (path) => {
  const config = Config.fromPath(path);
  if (!config) {
    return [];
  }
  const definedOptions = getDefinedOptions(path);
  return config.children
    .map((child) => {
      if (child.children.length === 1) {
        child = child.children[0];
        const filterConditionSummary = getFilterConditionSummary(child);
        if (filterConditionSummary) {
          const definition = definedOptions.find(
            (option) => option.id === child.key
          );
          const option = {...definition};
          option.value = child.data.value;
          option.label = filterConditionSummary;
          option.config = child;
          return option;
        }
      }
      return null;
    })
    .filter((option) => option !== null);
};

function FilterInputField({
                            path,
                            handlePatternChange,
                            pattern: initialPattern,
                            isInsideGrid,
                          }) {
  const classes = useStyles();
  const [
    isPendingCustomizationOption,
    setIsPendingCustomizationOption,
  ] = React.useState(false);
  const [renderFlag, setRenderFlag] = React.useState(false);
  const pendingCustomizationOption = React.useRef(null);

  // useEffect(() => {
  //   if (!isPendingCustomizationOption) {
  //     const pattern = selectedOptions.current
  //       .map((option) => option.getPattern())
  //       .join("");
  //     if (handlePatternChange) {
  //       handlePatternChange(pattern);
  //     }
  //   }
  // }, [isPendingCustomizationOption, renderFlag, handlePatternChange]);

  const onChange = (event, values, reason) => {
    if (reason === "select-option") {
      const selectedValue = values[values.length - 1];

      const newItem = Config.getDefault(`${path}:filter`);
      newItem.children = newItem.children.filter(
        (child) => child.key === selectedValue.key
      );
      const parent = Config.fromPath(path, false, true);
      Config.addChild(parent, newItem, false);

      pendingCustomizationOption.current = newItem.children[0];
      setIsPendingCustomizationOption(true);
    } else if (reason === "remove-option") {
      const remainingFilters = values.map((value) => value.config.parent);
      const config = Config.fromPath(path);
      for (let i = config.children.length - 1; i >= 0; i--) {
        if (!remainingFilters.includes(config.children[i])) {
          Config.deleteChild(path, i);
        }
      }
    } else if (reason === "clear") {
      const config = Config.fromPath(path);
      for (let i = config.children.length - 1; i >= 0; i--) {
        Config.deleteChild(path, i);
      }
    }
    forceRender();
  };

  const forceRender = () => {
    setRenderFlag(!renderFlag);
  };

  const handleApplyChanges = () => {
    console.log("handleApplyChanges");
    setIsPendingCustomizationOption(false);
  };

  const handleCancelChanges = () => {
    // Delete last configuration child
    const config = Config.fromPath(path);
    if (config && config.children.length > 0) {
      Config.deleteChild(path, config.children.length - 1);
    }
    setIsPendingCustomizationOption(false);
  };

  const definedOptions = getDefinedOptions(path);
  const configuredOptions = getConfiguredOptions(path);
  const customizationPath = pendingCustomizationOption.current
    ? Config.toPath(pendingCustomizationOption.current)
    : null;
  return (
    <div className={classes.root}>
      <FilterByProjectDialog
        path={customizationPath}
        open={
          isPendingCustomizationOption &&
          pendingCustomizationOption.current.key === "filterByProject"
        }
        handleApplyChanges={handleApplyChanges}
        handleClose={handleCancelChanges}
      />
      <FilterByDateDialog
        path={customizationPath}
        open={
          isPendingCustomizationOption &&
          pendingCustomizationOption.current.key === "filterByDate"
        }
        handleApplyChanges={handleApplyChanges}
        handleClose={handleCancelChanges}
      />
      <FilterByCodeDialog
        path={customizationPath}
        open={
          (isPendingCustomizationOption &&
            pendingCustomizationOption.current.key === "filterByProjCode") ||
          (isPendingCustomizationOption &&
            pendingCustomizationOption.current.key === "filterByActCode")
        }
        handleApplyChanges={handleApplyChanges}
        handleClose={handleCancelChanges}
      />
      <Autocomplete
        multiple
        id="filter-conditions"
        filterOptions={(options, state) => {
          return definedOptions.map((option) => ({...option}));
        }}
        value={configuredOptions}
        options={definedOptions}
        getOptionLabel={(option) => option.label}
        filterSelectedOptions
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={"outlined"}
            label="Filter By..."
            placeholder={isInsideGrid ? "" : "AND..."}
            helperText={"Tap to define filter conditions"}
          />
        )}
      />
    </div>
  );
}

export default FilterInputField;
